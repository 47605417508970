import './App.css';
import Router from './Router';

function App() {
  return (
    <div className={"mainLayout"}>
      <div className={"backgroundImage"}/>
        <div className={"panel"}>
            <div className={"header"}>
                <div className="clientLogoImage">
                    <img
                    src="/logo.png"
                    style={{maxHeight: "100px"}}
                    className="img-centre"/>
                </div>
            </div>
          <Router>
          </Router>
        </div>
      </div>
  );
}

export default App;
