import { configureStore } from '@reduxjs/toolkit'
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from '@reduxjs/toolkit/query'
import { photosApi, customerReducer } from '../features/customer/photosSlice'
import { adminApi, adminReducer } from '../features/customer/adminSlice'

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [photosApi.reducerPath]: photosApi.reducer,
    customer: customerReducer,
    [adminApi.reducerPath]: adminApi.reducer,
    admin: adminReducer


  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(photosApi.middleware),
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)