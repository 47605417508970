import React from "react"; 
import ProductBanner from "../../component/productBanner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import UploadGrid from "../../component/UploadGrid";
import Cart from "../../component/Cart";
import axios from "axios"
import { Navigate } from "react-router-dom";
import { useLazyAdminCustomerQuery, setCustomer} from "../../features/customer/adminSlice"
import { useSelector, useDispatch } from "react-redux"
import DragNdrop from "../../component/DragNDrop"
import { useForm } from "react-hook-form";

import { useParams } from "react-router-dom";

let BaseURL = process.env.REACT_APP_BASEURL

const photoPackage = {
    qty:1,
    name: "Santa Package",
    package: {name:"Santa Package"},
    photo: {id: "abcd123", path: "/QVB2212345/abcd123.png", url: "dropbox.com/image.png"},
    price: 4500
}

const Photos = () => {
    const { code : codeParams } = useParams();

    const customer = useSelector((state) => state.admin)
    const dispatch = useDispatch()

    const { code, email, customerId} = useSelector((state) => {
        console.log(state)
        return state.admin
        
    })

    const [redirectTo, setRedirectTo] = React.useState()

    /* const {data : files, error, isLoading} = useSearchForCodeQuery(code) */
    const [loadingCheckoutLink , setLoadingCheckoutLink] = React.useState(false)

    const [uploadFiles, setUploadFiles] = React.useState([]);

    React.useEffect(()=>{
        /* if (code.length < 1){
            setRedirectTo('/')
        } */
    },[])
    

    const uploadFileToDBHandler =()=>{
        setLoadingCheckoutLink(true)
        axios.post(`${BaseURL}/public/order`,{
            "customer" : {
                "code": code,
                "customerId": customerId,
                "email": email
            },
            "order" : {
                "items": order.items
            }
        })
            .then(res=>{
                window.location.replace(res.data.paymentLink)
                setLoadingCheckoutLink(false)
            })
            .catch(err=>{
                alert('An error occured when getting checkout link, please try again.')
                setLoadingCheckoutLink(false)
                console.log(err)
            })
    }

    const { register, handleSubmit, watch, setError, formState: {errors}, setValue, reset, } = useForm({
        mode: "onBlur",
        reset: {keepValues: true},
        defaultValues : {code: codeParams}
      });

    const uploadFileHandler = (uploadFile) => {
        const uniqueCode = "QVB23ABCDE"
        const fileArray = Array.from(uploadFile)
        if (fileArray.length > 0) {
            console.log(fileArray, "File")
            axios.post(`${BaseURL}/public/files/uploadLink/${uniqueCode}`, {files:fileArray.map(file=>file.name)})
                .then(res=>{
                    res.data.forEach(file=> {
                        var formData = new FormData();
                        formData.append("image", uploadFile[0]);
                        axios.post(file.link, uploadFile[0], {headers: { 'content-type': "application/octet-stream"}})
                        console.log(file,uploadFile[0])
                    })
                    
                })
          }
    }

    const [creatingCard, setCreatingCard] = React.useState(false)
    
    const createCard = (data) => {
    
        axios.post(`${BaseURL}/public/files/admin/createCode`, {email: data.email})
        .then(res=>{
            const tempData = {code: res.data.cards[0].code, email: res.data.cards[0].email, link: res.data.result.metadata.path_display}
            console.log(res.data)
            setCreatingCard(false)
            dispatch(setCustomer(tempData))
            setRedirectTo(`/admin/customer`)
        })
    }
    
    const downloadLinkHandler = (imageId) => {
        window.open(imageId, '_blank')
        return
    }
    
    const [showNewLinkModal, setShowNewLinkModal] = React.useState(false)

    const [customers, setCustomers] = React.useState([])

    React.useEffect(()=>{
        axios.get(`${BaseURL}/public/files/admin/customers`)
            .then(response => {
                setCustomers(response.data.response.Cards)
            })
    },[])

    console.log(customers)

    return <>
        {redirectTo && <Navigate to={redirectTo}/>}
        ADMIN PAGE
        {showNewLinkModal && <>
            <div className="ModalBackground" onClick={()=>setShowNewLinkModal(false)}></div>
            <div className="Modal">
            
            <div className="ModalContent">
                <p>
                    ENTER Customer email
                </p>
                <form onSubmit={handleSubmit(createCard)}>
                    <input placeholder="Email Address" defaultValue="" style={{textAlign: "center", border:" 2px solid #8c8c8c96"}} {...register("email", {required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i})} aria-invalid={errors.email ? "true" : "false"}/>
                    {errors.email?.type === 'required' && <p className="error" role="alert">Email is required</p>}
                    {errors.email?.type === 'pattern' && <p className="error" role="alert">Email is invalid</p>}
                    { creatingCard ?  <div className={"loading-spinner"}>
                            <FontAwesomeIcon icon={faCircleNotch} spin/>
                    </div> : <button type="submit">Create</button>}
                    
                </form>
            </div>
            
            
        </div>
        </>
        }
        <div onClick={()=>{setShowNewLinkModal(true)}}><button type="submit">Create New Link</button></div>
        <div style={{padding: "20px"}}>
            <table>
                <tr>
                    <th>Code</th>
                    <th>Email</th>
                    <th>Date Created</th>
                </tr>
                {customers.length > 0 ? customers.map((cust, i)=><tr index={`customerTableRow-${i}`}>
                    <td>{cust.code}</td>
                    <td>{cust.email}</td>
                    <td>{cust.createdAt}</td>
                </tr>) : ""
                }
                
                


            </table>
        </div>
    </>
}

export default Photos