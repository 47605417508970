import React from "react";
import { useVisibility } from "reactjs-visibility"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart, faTrashCan, faCircleNotch } from '@fortawesome/free-solid-svg-icons'

const Cart = ({order, removeFromCartHandler, checkoutHandler, loadingCheckoutLink})=> {
    const [showCartSummaryTotal, setShowCartSummaryTotal] = React.useState(true)
    const handleChangeVisibility = (visible) => {
        visible ? setShowCartSummaryTotal(false) : setShowCartSummaryTotal(true)
    };
    
    const options = {threshold: 0.1};

    const { ref, visible } = useVisibility({
        onChangeVisibility: handleChangeVisibility,
        options,
    });

    return <>
        <div ref={ref} className="cartDetails">
            <div className="panel">
                <h2>Cart</h2>
                <table className="cartLargeMediaQuery">
                    <thead>
                        <tr>
                            <th style={{width:"10%"}}>
                                Quantity
                            </th>
                            <th>
                                Package
                            </th>
                            <th>
                                Photo
                            </th>
                            <th>
                                Price
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {order.items.map((item, index)=>(
                            <tr key={`order.items.${index}`}>
                                <td>
                                    {item.qty} 
                                    <div style={{padding: "5px", display:"inline", float:"right"}} onClick={()=>removeFromCartHandler(index)}>
                                        <FontAwesomeIcon color="red" icon={faTrashCan} />
                                    </div>
                                </td>
                                <td>
                                    {item.package.name} 
                                </td>
                                <td>
                                    {item.photo.id}
                                </td>
                                <th>
                                    ${(item.price/100).toFixed(2)}
                                </th>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="3" style={{textAlign:"right", paddingRight: "50px"}}>
                                Shipping
                            </td>
                            <th>
                                ${(order.shipping/100).toFixed(2)}
                            </th>
                        </tr>
                        <tr>
                            <th colSpan="3" style={{textAlign:"right", paddingRight: "50px"}}>
                                Total
                            </th>
                            <th>
                                ${(order.total/100).toFixed(2)}
                            </th>
                        </tr>
                    </tfoot>
                    
                </table>
                <table className="cartSmallMediaQuery">
                    <thead>
                        <tr>
                            <th>
                                Item
                            </th>
                            <th>
                                Price
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {order.items.map((item, index)=>(
                            <tr key={`order.items.${index}`}>
                                <td>
                                    {item.qty} x {item.package.name} 
                                    <p style={{paddingLeft: "5px"}}>
                                        <i>{item.photo.id}</i>
                                    </p>
                                </td>
                                <th>
                                    ${(item.price/100).toFixed(2)}
                                    <div style={{padding: "5px", display:"inline", float:"right"}} onClick={()=>removeFromCartHandler(index)}>
                                        <FontAwesomeIcon color="red" icon={faTrashCan} />
                                    </div>
                                </th>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="1" style={{textAlign:"right", paddingRight: "50px"}}>
                                Shipping
                            </td>
                            <th>
                                ${(order.shipping/100).toFixed(2)}
                            </th>
                        </tr>
                        <tr>
                            <th colSpan="1" style={{textAlign:"right", paddingRight: "50px"}}>
                                Total
                            </th>
                            <th>
                                ${(order.total/100).toFixed(2)}
                            </th>
                        </tr>
                    </tfoot>
                    
                </table>
                {loadingCheckoutLink && <div className={"loading-spinner"}>
                    <FontAwesomeIcon icon={faCircleNotch} spin/>
                </div>}
                <div className={"positionLeft cartActions"}>
                    <div className={"checkout-button"} onClick={()=>checkoutHandler()}>
                        <div style={{padding: "5px"}}>
                            <FontAwesomeIcon icon={faShoppingCart} />
                        </div>
                        <div style={{padding: "5px"}}>Check Out</div>
                    </div>
                </div>
                

            </div>
        </div>
        {showCartSummaryTotal ?(<>
            <div className="cartSummaryTotalContainer grid-container cartSummaryMediaQuery">
                    <div className="cartTotalDetails">
                        <div>
                            <b>Cart</b>
                        </div>
                        <div>
                            {order.items.length} items
                        </div>
                    </div>
                    <div className="cartTotalDetails">
                        <b>Total</b>
                        <div>
                            ${(order.total/100).toFixed(2)}
                        </div>
                    </div>
                    
            </div>
            <div className="cartSummaryMediaQuery" style={{height:"50px"}}></div>
        </>) : null}

    </>
}

export default Cart
