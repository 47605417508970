import React from "react"; 
import ProductBanner from "../../component/productBanner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import UploadGrid from "../../component/UploadGrid";
import Cart from "../../component/Cart";
import axios from "axios"
import { Navigate } from "react-router-dom";
import { useSearchForCodeQuery, setCustomer} from "../../features/customer/photosSlice"
import { useSelector, useDispatch } from "react-redux"
import DragNdrop from "../../component/DragNDrop";

let BaseURL = process.env.REACT_APP_BASEURL
const imageMimeType = /image\/(png|jpg|jpeg)/i;

const photoPackage = {
    qty:1,
    name: "Santa Package",
    package: {name:"Santa Package"},
    photo: {id: "abcd123", path: "/QVB2212345/abcd123.png", url: "dropbox.com/image.png"},
    price: 4500
}

const Photos = () => {
    const { code, email, link} = useSelector((state) => state.admin)

    const [redirectTo, setRedirectTo] = React.useState()

    const {data : files, error, isLoading} = useSearchForCodeQuery(code)
    const [loadingCheckoutLink , setLoadingCheckoutLink] = React.useState(false)

    const [uploadFiles, setUploadFiles] = React.useState([]);
    const [uploadedFiles, setUploadedFiles] = React.useState([]);

    const [failedFiles, setFailedFiles] = React.useState([]);

    React.useEffect(()=>{
        if (code.length < 1){
            setRedirectTo('/admin')
        }
    },[]) 
    

    const uploadFileToDBHandler =()=>{
        setLoadingCheckoutLink(true)
        axios.post(`${BaseURL}/public/order`,{
            "customer" : {
                "code": code,
                "customerId": customerId,
                "email": email
            },
            "order" : {
                "items": order.items
            }
        })
            .then(res=>{
                window.location.replace(res.data.paymentLink)
                setLoadingCheckoutLink(false)
            })
            .catch(err=>{
                alert('An error occured when getting checkout link, please try again.')
                setLoadingCheckoutLink(false)
                console.log(err)
            })
    }
    const [firstFile, SetFirstFile] = React.useState(true)
    const [uploading, SetUploading] = React.useState([])

    const uploadFileHandler = (uploadFile) => {
        const uniqueCode = code
        const fileArray = Array.from(uploadFile)
        SetUploading((tempUploading)=>[...tempUploading, ...fileArray])

        console.log(uploading)
        if (fileArray.length > 0) {
            console.log(fileArray, "File")
            axios.post(`${BaseURL}/public/files/uploadLink/${uniqueCode}`, {files:fileArray.map(file=>file.name)})
                .then(res=>{
                    res.data.forEach((file,index)=> {
                        var formData = new FormData();
                        const tempFile = uploadFile[index]
                        formData.append("image", tempFile );
                        axios.post(file.link, uploadFile[index], {headers: { 'content-type': "application/octet-stream"}})
                        .then(()=>{
                            if (firstFile && index == 0) {
                                axios.post(`${BaseURL}/public/files/admin/sendEmail`, {code, to: email, link: `https://mpl.stardom.photos/${code}`})
                                .then(Response=>{console.log(Response)})
                                .catch(error=>console.log(error))
                                SetFirstFile(false)
                            }
                            setUploadedFiles((prevFiles) => [...prevFiles, tempFile]);
                            setUploadFiles((prevFiles) => {
                                const j =Array.from(prevFiles)
                                return prevFiles.filter((_, i) => i !== j.findIndex((k)=>tempFile.name == k.name))});
                            setFailedFiles((prevFiles) => {
                                    const j =Array.from(prevFiles)
                                    return prevFiles.filter((_, i) => i !== j.findIndex((k)=>tempFile.name == k.name))});

                        })
                        .catch (err => {
                            setFailedFiles((prevFiles) => [...prevFiles, tempFile]);
                            setUploadFiles((prevFiles) => {
                                const j =Array.from(prevFiles)
                                return prevFiles.filter((_, i) => i !== j.findIndex((k)=>tempFile.name == k.name))});
                        })
            
                    })
                    
                })
          }
    }
    const [file, setFile] = React.useState(null);
    const [fileDataURL, setFileDataURL] = React.useState(null);

    React.useEffect(() => {
        let fileReader, isCancel = false;
        if (file) {
          fileReader = new FileReader();
          fileReader.onload = (e) => {
            const { result } = e.target;
            if (result && !isCancel) {
              setFileDataURL(result)
            }
          }
          fileReader.readAsDataURL(file);
        }
        return () => {
          isCancel = true;
          if (fileReader && fileReader.readyState === 1) {
            fileReader.abort();
          }
        }
    
      }, [file]);

      const uploadFilesArray = Array.from(uploadFiles)
      const uploadedFilesArray = Array.from(uploadedFiles)
      const failedFilesArray = Array.from(failedFiles)
    
    

    return <>
        {redirectTo && <Navigate to={redirectTo}/>}
        <div style={{background: "blueviolet"}} className={"flexContainer"}>
            <div><h1>ADMIN PAGE </h1></div>
            <div><h1>CODE: {code}</h1></div>
            <div><h1>EMAIL: {email}</h1></div>
        </div>
        <DragNdrop onFilesSelected={setUploadFiles} uploadFileHandler={uploadFileHandler} width="100%" height='400px'/>
        {/* {   
        (uploadFiles.length > 0) ? <img src={}/> : <></>
            
        } */}

        {failedFilesArray.length > 0 ? <div style={{background:"white"}}>
        <div><h1 style={{color: "black"}}>Failed Files, Try again</h1></div>
        <div className={"flexContainer"} style={{justifyContent: "center"}}>
            {failedFilesArray.map((f,i)=> {
                console.log(f)
                return <><p>File Name: {f.name}</p><img className={"adminGallery"} index={`failedImage-${i}`}src={URL.createObjectURL(f)}></img></>
            })}

        </div></div>: null}

        {uploadFilesArray.length > 0 ? <div style={{background:"white"}}>
        <div><h1 style={{color: "black"}}>...Uploading Files...</h1></div>
        <div className={"flexContainer"} style={{justifyContent: "center"}}>
            {uploadFilesArray.map((f,i)=> {
                console.log(f)
                return <><p>File Name: {f.name}</p><img className={"adminGallery"} index={`uploadImage-${i}`}src={URL.createObjectURL(f)}></img></>
            })}

        </div></div>: null}

        {uploadedFilesArray.length > 0 ? <div style={{background:"white"}}>
        <div><h1 style={{color: "black"}}>Upload Completed Files</h1></div>
        <div className={"flexContainer"} style={{justifyContent: "center"}}>
            {uploadedFilesArray.map((f,i)=> {
                console.log(f)
                return <><p>File Name: {f.name}</p><img className={"adminGallery"} index={`uploadedImage-${i}`}src={URL.createObjectURL(f)}></img></>
            })}

        </div></div>: null}


    </>
}

export default Photos