import React from "react";
import { BrowserRouter as Router, Routes, Route, BrowserRouter, Navigate} from "react-router-dom";
import SearchPage from './page/search';
import Photos from './page/photos'
import Admin from './page/admin'
import Customer from './page/admin/adminCustomer'

export default ({children}) => {
    return (
        <>
            {children}
            <Router>
                <Routes>
                    <Route
                            path="/"
                            element={<Navigate to="/search" replace />}
                    />
                    <Route index path="/:code" element={<SearchPage/>}/>
                    <Route index path="/search" element={<SearchPage/>}/>
                    <Route index path="/admin" element={<Admin/>}/>
                    <Route index path="/admin/customer" element={<Customer/>}/>
                    <Route index path="/photos" element={<Photos/>}/>
                    <Route
                        path="*"
                        element={<Navigate to="/" replace />}
                    />
                </Routes>
            </Router>
        </>
    )
}