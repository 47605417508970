import React from "react"; 
import ProductBanner from "../component/productBanner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import ImageGrid from "../component/ImageGrid";
import Cart from "../component/Cart";
import axios from "axios"
import { Navigate } from "react-router-dom";
import { useSearchForCodeQuery, setCustomer} from "../features/customer/photosSlice"
import { useSelector, useDispatch } from "react-redux"

let BaseURL = process.env.REACT_APP_BASEURL

const photoPackage = {
    qty:1,
    name: "Santa Package",
    package: {name:"Santa Package"},
    photo: {id: "abcd123", path: "/QVB2212345/abcd123.png", url: "dropbox.com/image.png"},
    price: 4500
}

const Photos = () => {
    const { code, email, customerId} = useSelector((state) => state.customer)

    const [redirectTo, setRedirectTo] = React.useState()

    const {data : files, error, isLoading} = useSearchForCodeQuery(code)
    const [loadingCheckoutLink , setLoadingCheckoutLink] = React.useState(false)

    React.useEffect(()=>{
        if (code.length < 1){
            setRedirectTo('/')
        }
    },[])
    

    const checkoutHandler =()=>{
        setLoadingCheckoutLink(true)
        axios.post(`${BaseURL}/public/order`,{
            "customer" : {
                "code": code,
                "customerId": customerId,
                "email": email
            },
            "order" : {
                "items": order.items
            }
        })
            .then(res=>{
                window.location.replace(res.data.paymentLink)
                setLoadingCheckoutLink(false)
            })
            .catch(err=>{
                alert('An error occured when getting checkout link, please try again.')
                setLoadingCheckoutLink(false)
                console.log(err)
            })
    }
    
    const [order, setOrder] = React.useState({
        items: [],
        shipping: 1500,
        total: 6000,
        customer: {
            id: ""
        }})

    const downloadLinkHandler = (imageId) => {
        window.open(imageId, '_blank')
        return
    }

    const addToCartHandler = (image) => {
        const newOrder = {
            ...order,
        }
        newOrder.items.push({...photoPackage, photo: image}),
        console.log({...photoPackage, photo: image})
        newOrder.total = calculateTotal(newOrder)
        setOrder(newOrder)
    }

    const removeFromCartHandler = (itemIndex) => {
        const newOrder = {
            ...order,
        } 
        newOrder.items.splice(itemIndex,1),
        newOrder.total = calculateTotal(newOrder)
        setOrder(newOrder)
    }

    const calculateTotal = (newOrder) => {
        let total = 0 
        newOrder.items.forEach(item=>{
            total = total + item.price
        })
        total = total + newOrder.shipping
        return total
    }
    return <>
        {redirectTo && <Navigate to={redirectTo}/>}
        {/* <ProductBanner/> */}
        <ImageGrid files={files} downloadClickHandler={downloadLinkHandler} addToCartHandler={addToCartHandler} code={code}/>
        {(order.items.length > 0)?<Cart order={order} removeFromCartHandler={removeFromCartHandler} checkoutHandler={checkoutHandler} loadingCheckoutLink={loadingCheckoutLink}/>: null}
    </>
}

export default Photos